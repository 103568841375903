<template>
  <v-container style="max-width:1200px;">
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <v-card flat style="border-radius:8px;">
          <v-card-title
            class="py-1"
            style="font-size:24px;color:green;border-bottom:1px solid green;"
          >
            <v-spacer />
            Thông Tin Phòng Ban
            <v-spacer />
          </v-card-title>
          <v-card-text class="px-8 py-4">
            <div
              v-if="displayDeptAfterFilter"
              style="width:100%;font-size:15px;"
            >
              <div>
                <div style="width:120px;display:inline-block;">
                  Mã Phòng Ban:
                </div>
                <div v-if="!editDeptMode" style="display:inline-block;">
                  {{ displayDeptAfterFilter.id }}
                </div>
              </div>
              <div>
                <v-text-field
                  v-if="editDeptMode"
                  flat
                  dense
                  v-model="dept.id"
                  :rules="[rules.required]"
                  color="#DD0011"
                  clearable
                  outlined
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <div>
                <div style="width:120px;display:inline-block;">
                  Tên Phòng Ban:
                </div>
                <div v-if="!editDeptMode" style="display:inline-block;">
                  {{ displayDeptAfterFilter.name }}
                </div>
              </div>
              <div>
                <v-text-field
                  v-if="editDeptMode"
                  flat
                  dense
                  v-model="dept.name"
                  :rules="[rules.required]"
                  color="#DD0011"
                  clearable
                  outlined
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Ngày đăng ký
                </div>
                <div style="display:inline-block;">
                  {{
                    $moment(displayDeptAfterFilter.createdDate).format(
                      "HH:mm DD-MM-YYYY"
                    )
                  }}
                </div>
              </div>

              <div class="my-2">
                <v-btn
                  v-if="!editDeptMode"
                  text
                  class="text-none my-1"
                  style=""
                  color="#1565C0"
                  @click="
                    editDeptMode = true;
                  "
                >
                  Chỉnh sửa thông tin
                </v-btn>

                <v-btn
                  v-if="editDeptMode"
                  style=""
                  color="#BBBBBB"
                  dark
                  class="mr-2  my-1"
                  @click="editDeptMode = false"
                >
                  Hủy bỏ
                </v-btn>
                <v-btn
                  v-if="editDeptMode"
                  class="my-1"
                  style="min-width:160px;"
                  color="#DD0011"
                  dark
                  @click="callUpdateDept"
                >
                  Lưu
                </v-btn>
              </div>
            </div>
          </v-card-text>
          <!-- <v-card-text class="px-8 py-4">
            <table v-if="dept" style="width:100%;font-size:16px;">
              <tr>
                <td
                  class="pr-6"
                  style="width:140px;max-width:140px;text-align:right;"
                >
                  Mã phòng ban:
                </td>
                <td style="min-width:300px;" class="py-1">
                  <div>
                    {{ dept.id }}
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  class="pr-6"
                  style="width:140px;max-width:140px;text-align:right;"
                >
                  Tên Phòng Ban:
                </td>
                <td style="min-width:300px;" class="py-1">
                  <div>
                    {{ dept.name }}
                  </div>
                </td>
              </tr>
            </table>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayDeptAfterFilter() {
      let result = null;
      if (!this.dept) return null;
      result = JSON.parse(JSON.stringify(this.dept));
      return result;
    },
  },
  data() {
    return {
      roles: ['user-manager', 'admin'],
      dept: null,
      editDeptMode: false,
      rules: {
        required: (value) => !!value || "* Required",
        passRule: (v) => (v && v.length >= 6) || "Min 6 chars",
        username: (v) => (v && v.length >= 6) || "Min 6 chars",
        fullname: (v) => (v && v.length >= 3) || "Min 3 chars",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
    };
  },
  methods: {
    callUpdateDept() {
      if (!this.dept) return;
      if (!this.dept.id || this.dept.id.length <= 0) {
        alert("Mã phòng ban không được để trống!");
        return;
      }
      if (!this.dept.name || this.dept.name.length <= 0) {
        alert("Tên phòng ban không được để trống!");
        return;
      }

      let self = this;
      this.axios
        .put(
          this.$root.apiUser + "/depts/update/" + self.dept._id,
          {
            id: this.dept.id,
            name: this.dept.name,
          },
          {
            headers: {
              "x-auth": this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            self.dept = response.data.content;
            self.editDeptMode = false;
            alert("Cập nhật thành công!");
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    fetchUser() {
      let self = this;
      self.axios
        .get(self.$root.apiUser + "/depts/get/" + this.$route.params.dept_id, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.dept = res.data.content;
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUser();
    });
  },
};
</script>

<style></style>
